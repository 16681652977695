import { default as indexDpn9ESbViuMeta } from "/opt/docker/pages/basket/index.vue?macro=true";
import { default as indexptpTMl1h8hMeta } from "/opt/docker/pages/basket/order-done/index.vue?macro=true";
import { default as basketDPbBARR1vYMeta } from "/opt/docker/pages/basket.vue?macro=true";
import { default as _91id_93RwW30KcEGbMeta } from "/opt/docker/pages/catalog/[id].vue?macro=true";
import { default as _91id_9395P7WbDXf6Meta } from "/opt/docker/pages/catalog/brands/[id].vue?macro=true";
import { default as indexc5Rzs9XiPNMeta } from "/opt/docker/pages/catalog/brands/index.vue?macro=true";
import { default as _91id_938mWt0JV2xNMeta } from "/opt/docker/pages/catalog/brands/model/[id].vue?macro=true";
import { default as indexJ5GsxvIo3uMeta } from "/opt/docker/pages/catalog/brands/model/index.vue?macro=true";
import { default as _91id_93NjpK4399FBMeta } from "/opt/docker/pages/catalog/brands/product/[id].vue?macro=true";
import { default as indexfzsSiV04mBMeta } from "/opt/docker/pages/catalog/index.vue?macro=true";
import { default as _91id_9370xmvmB3E5Meta } from "/opt/docker/pages/catalog/manufacturers/[id].vue?macro=true";
import { default as indexzuSlmH8SneMeta } from "/opt/docker/pages/catalog/manufacturers/index.vue?macro=true";
import { default as _91id_933YV2PXcuaJMeta } from "/opt/docker/pages/catalog/product/[id].vue?macro=true";
import { default as indexV3A1Ngd8UtMeta } from "/opt/docker/pages/catalog/search/index.vue?macro=true";
import { default as _91id_93nminDfQ43CMeta } from "/opt/docker/pages/catalog/section/[id].vue?macro=true";
import { default as catalogu81eibC0XkMeta } from "/opt/docker/pages/catalog.vue?macro=true";
import { default as indexl1AcxuUKXrMeta } from "/opt/docker/pages/favourites/index.vue?macro=true";
import { default as favouritespdW8gxXLVmMeta } from "/opt/docker/pages/favourites.vue?macro=true";
import { default as indexsWiwm9unDIMeta } from "/opt/docker/pages/index.vue?macro=true";
import { default as indexPMt2XfF9amMeta } from "/opt/docker/pages/login/change-password/index.vue?macro=true";
import { default as indexRkyr1Sx5qOMeta } from "/opt/docker/pages/login/forgot-password/index.vue?macro=true";
import { default as indexecHqu2clOSMeta } from "/opt/docker/pages/login/index.vue?macro=true";
import { default as indexdUqzgz8GE4Meta } from "/opt/docker/pages/login/registration/index.vue?macro=true";
import { default as indexgs1bK1boBvMeta } from "/opt/docker/pages/privacy/index.vue?macro=true";
import { default as indexlb5DMRyREZMeta } from "/opt/docker/pages/profile/contacts/index.vue?macro=true";
import { default as indexii4PnQnMPhMeta } from "/opt/docker/pages/profile/data/index.vue?macro=true";
import { default as indexKuQWP3o6edMeta } from "/opt/docker/pages/profile/documents/index.vue?macro=true";
import { default as _91id_93pZ4pTB1eF9Meta } from "/opt/docker/pages/profile/history/[id].vue?macro=true";
import { default as indexPVX2vAL07zMeta } from "/opt/docker/pages/profile/history/index.vue?macro=true";
import { default as indexIgoF5Cqw7yMeta } from "/opt/docker/pages/profile/implementations/index.vue?macro=true";
import { default as indexPx2R7pqVlLMeta } from "/opt/docker/pages/profile/index.vue?macro=true";
import { default as indexemjD0KaqcSMeta } from "/opt/docker/pages/profile/reports/index.vue?macro=true";
import { default as index78FGJYWiFgMeta } from "/opt/docker/pages/profile/settings/index.vue?macro=true";
import { default as profileYTlFDMVeHtMeta } from "/opt/docker/pages/profile.vue?macro=true";
export default [
  {
    name: basketDPbBARR1vYMeta?.name,
    path: "/basket",
    component: () => import("/opt/docker/pages/basket.vue").then(m => m.default || m),
    children: [
  {
    name: "basket",
    path: "",
    component: () => import("/opt/docker/pages/basket/index.vue").then(m => m.default || m)
  },
  {
    name: "basket-order-done",
    path: "order-done",
    component: () => import("/opt/docker/pages/basket/order-done/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: catalogu81eibC0XkMeta?.name,
    path: "/catalog",
    component: () => import("/opt/docker/pages/catalog.vue").then(m => m.default || m),
    children: [
  {
    name: "catalog-id",
    path: ":id()",
    component: () => import("/opt/docker/pages/catalog/[id].vue").then(m => m.default || m)
  },
  {
    name: "catalog-brands-id",
    path: "brands/:id()",
    component: () => import("/opt/docker/pages/catalog/brands/[id].vue").then(m => m.default || m)
  },
  {
    name: "catalog-brands",
    path: "brands",
    component: () => import("/opt/docker/pages/catalog/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-brands-model-id",
    path: "brands/model/:id()",
    component: () => import("/opt/docker/pages/catalog/brands/model/[id].vue").then(m => m.default || m)
  },
  {
    name: "catalog-brands-model",
    path: "brands/model",
    component: () => import("/opt/docker/pages/catalog/brands/model/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-brands-product-id",
    path: "brands/product/:id()",
    component: () => import("/opt/docker/pages/catalog/brands/product/[id].vue").then(m => m.default || m)
  },
  {
    name: "catalog",
    path: "",
    component: () => import("/opt/docker/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-manufacturers-id",
    path: "manufacturers/:id()",
    component: () => import("/opt/docker/pages/catalog/manufacturers/[id].vue").then(m => m.default || m)
  },
  {
    name: "catalog-manufacturers",
    path: "manufacturers",
    component: () => import("/opt/docker/pages/catalog/manufacturers/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-product-id",
    path: "product/:id()",
    component: () => import("/opt/docker/pages/catalog/product/[id].vue").then(m => m.default || m)
  },
  {
    name: "catalog-search",
    path: "search",
    component: () => import("/opt/docker/pages/catalog/search/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-section-id",
    path: "section/:id()",
    component: () => import("/opt/docker/pages/catalog/section/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: favouritespdW8gxXLVmMeta?.name,
    path: "/favourites",
    component: () => import("/opt/docker/pages/favourites.vue").then(m => m.default || m),
    children: [
  {
    name: "favourites",
    path: "",
    component: () => import("/opt/docker/pages/favourites/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/docker/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login-change-password",
    path: "/login/change-password",
    meta: indexPMt2XfF9amMeta || {},
    component: () => import("/opt/docker/pages/login/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "login-forgot-password",
    path: "/login/forgot-password",
    meta: indexRkyr1Sx5qOMeta || {},
    component: () => import("/opt/docker/pages/login/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexecHqu2clOSMeta || {},
    component: () => import("/opt/docker/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login-registration",
    path: "/login/registration",
    meta: indexdUqzgz8GE4Meta || {},
    component: () => import("/opt/docker/pages/login/registration/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/opt/docker/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: profileYTlFDMVeHtMeta?.name,
    path: "/profile",
    component: () => import("/opt/docker/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "profile-contacts",
    path: "contacts",
    component: () => import("/opt/docker/pages/profile/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-data",
    path: "data",
    component: () => import("/opt/docker/pages/profile/data/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-documents",
    path: "documents",
    component: () => import("/opt/docker/pages/profile/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-history-id",
    path: "history/:id()",
    component: () => import("/opt/docker/pages/profile/history/[id].vue").then(m => m.default || m)
  },
  {
    name: "profile-history",
    path: "history",
    component: () => import("/opt/docker/pages/profile/history/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-implementations",
    path: "implementations",
    component: () => import("/opt/docker/pages/profile/implementations/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "",
    component: () => import("/opt/docker/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-reports",
    path: "reports",
    component: () => import("/opt/docker/pages/profile/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "settings",
    component: () => import("/opt/docker/pages/profile/settings/index.vue").then(m => m.default || m)
  }
]
  }
]