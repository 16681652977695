<template>
	<div :class="['search', { 'search--full': full }]">
		<form
			class="search__form"
			:class="{ 'search__form-results': showResults }"
			@submit.prevent="submit()">
			<label
				:for="id"
				class="search__icon">
				<svg class="icon">
					<use xlink:href="@/assets/sprites/sprite.svg#search"></use>
				</svg>
			</label>

			<input
				:id="id"
				v-model="searchPhrase"
				type="text"
				class="search__input"
				placeholder="Искать на RTT"
				@focus="inputActive = true"
				@focusout="inputActive = false"
				@input="search()" />
		</form>

		<div
			v-if="showResults"
			class="search__result-container"
			@mouseover="mouseOver = true"
			@mouseleave="mouseOver = false">
			<div class="search__result p6">
				<div
					v-if="searchResult.length && !loadingResult"
					class="search__items">
					<NuxtLink
						v-for="item in test"
						:key="item.id"
						:to="
							item.search_type === 'product'
								? `/catalog/product/${item.id}`
								: item.search_type === 'model'
									? `/catalog/brands/model/${item.id}`
									: `/catalog/brands/product/${item.id}`
						"
						:class="['search__item', item.search_type !== 'product' ? 'search__item--bold' : '']"
						@click="reset()">
						<div v-html="highlight(item.name, searchPhrase)"></div>
					</NuxtLink>
				</div>

				<NuxtLink
					v-if="searchResult.length && !loadingResult"
					:to="`/catalog/search?query=${searchPhrase}`"
					class="search__item-all"
					@click="reset()"
					>Все результаты
				</NuxtLink>

				<Loader
					v-if="loadingResult && !searchResult.length"
					class="search__loader"></Loader>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { useNuxtApp, useState } from "#app";
	import { ref } from "vue";
	import Loader from "../loader/loader";

	defineProps({
		id: {
			required: true,
			type: String,
		},
		full: {
			type: Boolean,
			required: false,
			default: false,
		},
	});

	const { api, $debounce } = useNuxtApp();
	const inputActive = ref(false);
	const mouseOver = ref(false);
	const loadingResult = ref(false);
	const submitting = useState("submitting", () => false);
	const searchPhrase = useState("search-phrase-modal", () => "");
	const searchResult = useState("searchResult", () => []);

	const highlight = (content, phrase) => {
		let text = content;

		if (!phrase || typeof phrase !== "string") return text;

		const phrases = phrase.trim().replace(/\s+/g, " ").split(" ").filter(Boolean);

		for (const phrase of phrases) {
			const escapedPhrase = phrase.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
			const regexp = new RegExp(escapedPhrase, "gi");
			text = text.replace(regexp, `<span class="search_item-request">$&</span>`);
		}

		return text;
	};

	const search = () => {
		submitting.value = false;
		searchResult.value = [];

		if (searchPhrase.value) {
			onFetch();
		}
	};

	const submit = () => {
		if (searchPhrase.value.length > 1) {
			useRouter().push(`/catalog/search?query=${searchPhrase.value}&searchForm=true`);
			reset();
			submitting.value = true;
		}
	};

	const reset = () => {
		loadingResult.value = false;
		mouseOver.value = false;
		searchPhrase.value = "";
		searchResult.value = [];
	};

	const test = computed(() => searchResult.value.slice(0, 5));

	const onFetch = $debounce(async () => {
		if (!submitting.value) {
			loadingResult.value = true;
		}
		searchResult.value = [];

		if (!searchPhrase.value.length) {
			return;
		}

		await $useFetch(`${api.search}?query=${searchPhrase.value}`, {
			method: "GET",
			onResponse({ response }) {
				if (response?.status === 200) {
					searchResult.value = response._data.data;
				}
			},
		});

		loadingResult.value = false;
	}, 500);

	const showResults = computed(() => mouseOver.value || (loadingResult.value && inputActive.value) || (inputActive.value && searchResult.value.length));
</script>

<style lang="scss">
	@import "search";
</style>
