<template>
	<div class="selection-accessories">
		<span class="p4 selection-accessories__text">Подбор аксессуаров</span>

		<nuxt-link
			class="p4 button button--red button--md-1 selection-accessories__button"
			to="/catalog/manufacturers"
			title="Подбор по производителям">
			По производителю
		</nuxt-link>

		<nuxt-link
			class="p4 button button--blue button--md-1 selection-accessories__button"
			to="/catalog/brands"
			title="Подбор по моделям">
			По модели
		</nuxt-link>
	</div>
</template>

<script setup></script>

<style lang="scss">
	@import "selection-accessories";
</style>
