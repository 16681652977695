/* global useAuth */
import { useFetch } from "#app";

export const $useFetch = (request, opts) => {
	const { data: userData } = useAuth();

	return useFetch(request, {
		headers: {
			"Authorization": `Bearer ${userData.value?.user?.accessToken}`,
			"Content-Type": "application/json",
		},
		...opts,
	});
};
