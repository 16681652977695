import { defineStore } from "pinia";
import { useNuxtApp } from "#app";
import { $useFetch } from "~/composables/useFetch";

export const useFavoritesInfoStore = defineStore("favoritesInfo", {
	state: () => ({
		favouritesCount: 0,
	}),
	actions: {
		fetchFavoritesInfo() {
			const { api } = useNuxtApp();
			const favoritesInfo = useFavoritesInfoStore();

			return $useFetch(api.favoriteCount, {
				onRequest({ options }) {
					options.method = "GET";
				},
				onResponse({ response }) {
					if (response?.status === 200) favoritesInfo.favouritesCount = response._data.data.count;
				},
			});
		},
	},
	getters: {
		getFavouritesCount() {
			return this.favouritesCount;
		},
	},
});
