/* global useAuth, computed */
import { navigateTo, defineNuxtRouteMiddleware } from "#app";

export default defineNuxtRouteMiddleware(async (from) => {
	const loginPath = "/login";
	const { status } = useAuth();
	const isAuth = computed(() => status.value === "authenticated");

	if (!isAuth.value && !from.path.includes(loginPath)) {
		return navigateTo(loginPath);
	}
});
