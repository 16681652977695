<template>
	<a
		v-if="number"
		:href="`tel:+${convertNumber(number)}`"
		class="phone-number link link--black"
		title="Позвонить">
		<svg class="phone-number__icon icon">
			<use xlink:href="@/assets/sprites/sprite.svg#phone"></use>
		</svg>

		<span class="p4 phone-number__number">{{ number }}</span>
	</a>
</template>

<script setup>
	defineProps({
		number: {
			type: String,
			required: true,
			default: "",
		},
	});

	const convertNumber = (tel) => tel.match(/\d/gi).join("");
</script>

<style scoped lang="scss">
	@import "phone-number";
</style>
