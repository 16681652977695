<template>
	<div class="header-buttons">
		<nuxt-link
			to="/favourites"
			class="header-buttons__link"
			active-class="active"
			title="Избранное">
			<svg class="icon header-buttons__icon">
				<use xlink:href="@/assets/sprites/sprite.svg#favorite"></use>
			</svg>

			<span
				v-if="favouritesInfoStore.getFavouritesCount > 0"
				class="header-buttons__count"
				:class="{ 'header-buttons__count--sm': favouritesInfoStore.getFavouritesCount > 9999 }">
				{{ favouritesInfoStore.getFavouritesCount }}
			</span>
		</nuxt-link>

		<nuxt-link
			to="/profile/data"
			class="header-buttons__link"
			active-class="active"
			title="Профиль">
			<svg class="icon header-buttons__icon">
				<use xlink:href="@/assets/sprites/sprite.svg#profile"></use>
			</svg>
		</nuxt-link>

		<nuxt-link
			to="/basket"
			class="header-buttons__link"
			active-class="active"
			title="Корзина">
			<div
				class="header-buttons__basket"
				:class="basketStore.getBasketQuantity === 0 ? 'header-buttons__basket--empty' : ''">
				<div class="header-buttons__basket-icon">
					<svg class="icon header-buttons__icon">
						<use xlink:href="@/assets/sprites/sprite.svg#shopping-bag"></use>
					</svg>
				</div>

				<div
					v-if="basketStore.getBasketQuantity > 0"
					class="header-buttons__basket-info">
					<div class="p3 header-buttons__basket-price">{{ useSplit(basketStore.getBasketCost) }} &#8381;</div>

					<div class="p6 header-buttons__basket-count">
						{{ basketStore.getBasketQuantity }}
						{{ useDeclension(basketStore.getBasketQuantity, ["позиция", "позиции", "позиций"]) }}
					</div>
				</div>
			</div>
		</nuxt-link>
	</div>
</template>

<script setup>
	import { useSplit } from "~/composables/useSplit";
	import { useBasketStore } from "@/store/basket/basket";
	import { useFavoritesInfoStore } from "@/store/favoritesInfo/favoritesInfo";
	import { useDeclension } from "@/composables/useDeclension";

	const basketStore = useBasketStore();
	await basketStore.fetchBasketInfo();

	const favouritesInfoStore = useFavoritesInfoStore();
	await favouritesInfoStore.fetchFavoritesInfo();
</script>

<style lang="scss">
	@import "header-buttons";
</style>
