import validate from "/opt/docker/node_modules/nuxt/dist/pages/runtime/validate.js";
import protected_45global from "/opt/docker/middleware/protected.global.js";
import manifest_45route_45rule from "/opt/docker/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  protected_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/docker/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}