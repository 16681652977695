/* global defineNuxtPlugin */
export default defineNuxtPlugin((nuxtApp) => {
	const debounce = (func, timeout) => {
		let timeoutId;
		return (...args) => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				func(...args);
			}, timeout);
		};
	};
	nuxtApp.provide("debounce", debounce);
});
