<template>
	<footer class="footer">
		<div class="footer__container container">
			<div
				v-if="$viewport.isGreaterOrEquals('md')"
				class="footer__main">
				<div class="footer__logo">
					<Logo></Logo>
				</div>

				<div class="footer__navigation">
					<Navigation :menu="menuStore.getMenuDesktop || []"></Navigation>
				</div>

				<div class="footer__numbers">
					<PhoneBlock :phones="menuStore.getMenuContacts"></PhoneBlock>
				</div>
			</div>

			<div class="footer__copyright text-center">
				<div class="p8">
					© {{ year }} «RTT Group»
					<nuxt-link
						class="link link--purple"
						to="/privacy"
						title="Политика конфиденциальности"
						>Политика конфиденциальности</nuxt-link
					>. При полном или частичном использовании материалов с сайта, ссылка на источник обязательна.
				</div>
			</div>
		</div>
	</footer>
</template>

<script setup>
	import moment from "moment";
	import Logo from "@/components/logo/logo.vue";
	import PhoneBlock from "@/components/phone-block/phone-block.vue";
	import Navigation from "@/components/navigation/navigation.vue";
	import { useMenuStore } from "@/store/menu/menu";

	const menuStore = useMenuStore();

	const year = moment().year();
</script>

<style lang="scss">
	@import "footer";
</style>
