/* global defineNuxtPlugin */
import { useRuntimeConfig } from "#app";

export default defineNuxtPlugin((nuxtApp) => {
	const config = useRuntimeConfig();
	const v1 = config.public.API_URL + "/api";
	nuxtApp.api = {
		// Версия апи
		versionApi: `${v1}/app/version/`,
		// Регистрация
		register: `${v1}/user/register/`,
		// Авторизация
		userLogin: `${v1}/user/login/`,
		// Обновление токена доступа
		refreshToken: `${v1}/user/token_refresh/`,
		// Выход
		userLogout: `${v1}/user/logout/`,
		// Запрос на сброс пароля | Установка нового пароля после сброса
		userReset: `${v1}/user/reset/`,
		// Получение информации о профиле пользователя
		// Обновление профиля пользователя
		// Установка нового пароля для авторизованного пользователя
		// Обновление настроек пользователя
		userInfo: `${v1}/user/`,
		// Метод получения списка избранного пользователя
		// Метод добавления в избранное
		// Метод удаления из избранного
		// Метод добавления в избранное
		userFavorite: `${v1}/user/favorite/`,
		// Метод получения значения избранного
		favoriteCount: `${v1}/user/favorite-count/`,
		// Метод получения корзины
		// Метод добавления товара в корзину
		// Метод удаления в товара из корзины
		// Метод очистить корзины
		userBasket: `${v1}/user/basket/`,
		// Метод получения краткой информации о корзине
		userBasketInfo: `${v1}/user/basket-info/`,
		// Метод получения последних просмотренных товаров
		userLastProducts: `${v1}/user/last-products/`,
		// Метод получения пользовательского баланса
		userBalance: `${v1}/user/balance/`,
		// Метод получения заказов пользователя
		// Метод добавления заказа
		// Метод получения заказа {orderId}
		// Метод получения отчета по заказу (Статус отчет) {orderId}/report/
		userOrder: `${v1}/user/order/`,
		// Метод получения списка отчетов пользователя
		userReportList: `${v1}/user/report-list/`,
		// Метод получения пользовательского отчета по параметрам
		userReport: `${v1}/user/report/`,
		// Список реализаций
		userRealization: `${v1}/user/realization/`,
		// Получить реализацию по uuid
		userUuidRealization: (id, ext) => `${v1}/user/realization/${id}/?extension=${ext}`,
		// Метод добавления обратной связи
		userFeedback: `${v1}/user/feedback/`,
		// Меню сайта
		userMenu: `${v1}/user/menu/`,
		// Метод получения каталога товаров
		catalog: `${v1}/catalog/`,
		// Метод получения списка разделов каталога
		section: `${v1}/section/`,
		// Метод получения дерева раздела каталога
		sectionList: `${v1}/section-list/`,
		// Метод получения товара
		product: `${v1}/product/`,
		// Список производителей
		manufacturer: `${v1}/manufacturer/`,
		// Получить список Марок товара
		brands: `${v1}/brands/`,
		// Список моделей
		models: `${v1}/models/`,
		// Метод получения умного фильтра
		smartFilter: `${v1}/smart-filter/`,
		// Метод получения похожих товаров
		similarProducts: `${v1}/similar-products/`,
		// Получить список документов
		documentList: `${v1}/document/list/`,
		// Получить документ
		document: `${v1}/document/`,
		// Получить контакты
		contacts: `${v1}/contacts/`,
		// Меню сайта
		menu: `${v1}/menu/`,
		// Метод получения параметров страницы
		page: `${v1}/page/`,
		// Слайдер для главной страницы
		slider: `${v1}/slider/`,
		// Метод получения блока баннеров
		bannerBlock: `${v1}/banner-block/`,
		// Выполнить поиск по сайту. Поиск по названию и по штрихкоду
		search: `${v1}/search/`,
		// Выполнить поиск по сайту. Поиск по названию
		searchLite: `${v1}/search-lite/`,
		// Получить контакты без авторизации
		phones: `${v1}/phones/`,
		// Получить IP пользователя
		userIp: `${v1}/user/ip/`,
		navChain: (sectionId) => `${v1}/nav-chain/${sectionId}/`,
	};
});
