<template>
	<header class="header">
		<div class="header__top">
			<div class="container">
				<div class="header__top-content">
					<div class="header__logo">
						<Logo></Logo>
					</div>

					<div
						v-if="$viewport.isGreaterOrEquals('md')"
						class="header__phone-block">
						<PhoneBlock :phones="menuStore.getMenuContacts"></PhoneBlock>
					</div>

					<Search :id="'search-header'"></Search>
				</div>
			</div>
		</div>

		<div
			v-if="$viewport.isGreaterOrEquals('md')"
			class="header__bottom">
			<div class="container">
				<div class="header__bottom-content">
					<nuxt-link
						class="button button--purple button--md header__catalog"
						title="Каталог"
						to="/catalog">
						<svg class="icon header__catalog-icon">
							<use xlink:href="@/assets/sprites/sprite.svg#catalog"></use>
						</svg>
						Каталог
					</nuxt-link>

					<SelectionAccessories></SelectionAccessories>

					<div class="header__nav-icons">
						<HeaderButtons></HeaderButtons>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script setup>
	import Logo from "@/components/logo/logo.vue";
	import Search from "@/components/search/search.vue";
	import PhoneBlock from "@/components/phone-block/phone-block.vue";
	import SelectionAccessories from "@/components/selection-accessories/selection-accessories.vue";
	import HeaderButtons from "@/components/header-buttons/header-buttons.vue";
	import { useMenuStore } from "~/store/menu/menu";

	const menuStore = useMenuStore();
</script>

<style lang="scss">
	@import "header";
</style>
