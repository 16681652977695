import { defineStore } from "pinia";
import { useNuxtApp } from "#app";
import { useMenuStore } from "@/store/menu/menu";
import { $useFetch } from "~/composables/useFetch";

export const useBasketStore = defineStore("basket", {
	state: () => ({
		basketCost: 0,
		basketQuantity: 0,
		userBasket: null,
		basketLoaded: false,
		basketMessage: "",
	}),
	actions: {
		fetchBasketInfo() {
			const { api } = useNuxtApp();

			return $useFetch(api.userBasketInfo, {
				onRequest({ options }) {
					options.method = "GET";
				},
				onResponse({ response }) {
					if (response?.status === 200) {
						useBasketStore().basketCost = response._data.cost;
						useBasketStore().basketQuantity = response._data.quantity;
					}
				},
			});
		},
		fetchUserBasket() {
			const { api } = useNuxtApp();

			return $useFetch(api.userBasket, {
				onRequest({ options }) {
					options.method = "GET";
				},
				onResponse({ response }) {
					if (response?.status === 200) {
						useBasketStore().userBasket = response._data;
						useBasketStore().basketLoaded = true;
						useBasketStore().basketMessage = "В корзине ещё нет товаров";
					}
				},
				onResponseError() {
					useBasketStore().basketLoaded = true;
					useBasketStore().basketMessage = "Ошибка обновления корзины";
				},
			});
		},
		clearBasket() {
			const { api, $viewport } = useNuxtApp();

			return $useFetch(api.userBasket, {
				onRequest({ options }) {
					options.method = "PATCH";
				},
				async onResponse({ response }) {
					if (response?.status === 200) {
						await useBasketStore().fetchUserBasket();
						await useBasketStore().fetchBasketInfo();

						if ($viewport.isLessThan("md")) await useMenuStore().fetchMenu();
					}
				},
			});
		},
		resetBasket() {
			this.userBasket = null;
		},
	},
	getters: {
		getBasketCost() {
			return this.basketCost;
		},
		getBasketQuantity() {
			return this.basketQuantity;
		},
		getUserBasket() {
			return this.userBasket;
		},
		getBasketMessage() {
			return this.basketMessage;
		},
	},
});
