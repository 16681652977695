<template>
	<div class="layout error-layout">
		<Header v-if="isAuth" />

		<main class="error">
			<div class="error__container container">
				<div class="error__inner">
					<h1
						v-if="error.statusCode === 404"
						class="error__title mb-16">
						404
					</h1>

					<h1
						v-if="error.statusCode === 500"
						class="error__title mb-16">
						500
					</h1>

					<div class="error__pre p1 mb-16">Что-то пошло не так...</div>

					<div
						v-if="error.statusCode === 404"
						class="error__description mb-24">
						Страница, на которую вы зашли, не зарегистрирована или ее не существует
					</div>

					<div
						v-if="error.statusCode === 500"
						class="error__description mb-24">
						Ошибка сервера
					</div>

					<NuxtLink
						to="/"
						class="error__button button button--lg button--purple"
						>Вернуться на главную
					</NuxtLink>
				</div>
			</div>
		</main>

		<LazyNavBar v-if="$viewport.isLessThan('md') && isAuth" />

		<Footer v-if="isAuth" />
	</div>
</template>

<script setup>
	import { useNuxtApp } from "#app";
	import { Header, Footer, LazyNavBar } from "#components";

	defineProps({
		error: {
			type: Object,
			required: true,
		},
	});

	const { $viewport } = useNuxtApp();
	const { status } = useAuth();

	const isAuth = computed(() => status.value === "authenticated");
</script>

<style lang="scss">
	@import "@/assets/scss/layouts/error-layout/error-layout";
	@import "@/assets/scss/pages/error/error";
</style>
