import { defineStore } from "pinia";
import { useNuxtApp } from "#app";
import { $useFetch } from "~/composables/useFetch";

export const useMenuStore = defineStore("menu", {
	state: () => ({
		menuMobile: [],
		menuDesktop: {},
		menuContacts: {},
	}),
	actions: {
		setMenu(menu) {
			if ("mobile" in menu) this.menuMobile = menu.mobile;
			if ("desktop" in menu) this.menuDesktop = menu.desktop;
			if ("contacts" in menu) this.menuContacts = menu.contacts;
		},
		setMenuMobile(val) {
			this.menuMobile = val;
		},
		setMenuDesktop(val) {
			this.menuDesktop = val;
		},
		setMenuContacts(val) {
			this.menuContacts = val;
		},
		fetchMenu() {
			const { api } = useNuxtApp();

			return $useFetch(api.menu, {
				onRequest({ options }) {
					options.method = "get";
				},
				onResponse({ response }) {
					useMenuStore().setMenu(response._data.data);
				},
			});
		},
	},
	getters: {
		getMenu() {
			return {
				mobile: this.menuMobile,
				desktop: this.menuDesktop,
				contacts: this.menuContacts,
			};
		},
		getMenuMobile() {
			return this.menuMobile["menu-items"];
		},
		getMenuDesktop() {
			return this.menuDesktop["menu-items"];
		},
		getMenuContacts() {
			return this.menuContacts;
		},
	},
});
