export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"}],"link":[{"rel":"icon","href":"favicons/favicon.ico","type":"image/x-icon"},{"rel":"apple-touch-icon","sizes":"32x32","href":"favicons/favicon-57.png"},{"rel":"apple-touch-icon","sizes":"57x57","href":"favicons/favicon-57.png"},{"rel":"apple-touch-icon","sizes":"60x60","href":"favicons/favicon-60.png"},{"rel":"apple-touch-icon","sizes":"72x72","href":"favicons/favicon-72.png"},{"rel":"apple-touch-icon","sizes":"76x76","href":"favicons/favicon-76.png"},{"rel":"apple-touch-icon","sizes":"114x114","href":"favicons/favicon-114.png"},{"rel":"apple-touch-icon","sizes":"120x120","href":"favicons/favicon-120.png"},{"rel":"apple-touch-icon","sizes":"144x144","href":"favicons/favicon-144.png"},{"rel":"apple-touch-icon","sizes":"152x152","href":"favicons/favicon-152.png"},{"rel":"apple-touch-icon","sizes":"167x167","href":"favicons/favicon-167.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"favicons/favicon-180.png"},{"rel":"apple-touch-icon","sizes":"1024x1024","href":"favicons/favicon-1024.png"},{"rel":"apple-touch-startup-image","sizes":"180x180","href":"favicons/favicon-180.png"}],"style":[],"script":[{"src":"https://api-maps.yandex.ru/2.1/?apikey=fc009e59-501c-4dac-baf5-8667cbcfd21f&lang=ru_RU"},{"src":"https://smartcaptcha.yandexcloud.net/captcha.js?render=onload"},{"name":"robots","content":"noindex, nofollow"},{"src":"https://mc.yandex.ru/metrika/tag.js","async":true,"tagPosition":"head"}],"noscript":[],"title":"RTT-GROUP"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false